import React from 'react'
import "../Styles/Components/Realization.scss";
import {motion} from "framer-motion";
import LazyLoadModal from "./LazyLoadModal";

const Realization = ({smallImage, image, description, variants, animate, large=false, withDescription = true, example = false}) => {
  return (
    <motion.div 
      className={`realization-container ${large ? "large" : "small" } ${example ? 'example' : ''}`}
      variants={variants}
      animate={animate}
      dragDirectionLock
    >
      <div className="content">
        <LazyLoadModal
            smallImage={smallImage}
            image={image}
            description={description}
        />
      </div>
      {withDescription &&
        <div className="description-container">
          <div className="description">{description}</div>
        </div>
      }
    </motion.div>
  )
}

export default Realization
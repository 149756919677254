import React from 'react'
import "../Styles/Components/LazyLoadModal.scss";
import ModalImage from 'react-modal-image';
import LazyLoad from "react-lazy-load";

const LazyLoadModal = ({image, smallImage, description}) => {

    return (
        <LazyLoad >
            <ModalImage
                small={smallImage}
                large={image}
                alt={description}
            />
        </LazyLoad>
    );
}

export default LazyLoadModal
import React from 'react'
import Title from '../Components/Title'
import Button from '../Components/Button';
import "../Styles/Sections/About.scss"
import Card from '../Components/Card';
import {BsPencilSquare} from 'react-icons/bs';
import {FaFileInvoice, FaCommentsDollar} from 'react-icons/fa';
import {GoPackage} from 'react-icons/go';
import {reveal} from "../Animation/Animiation";
import {motion} from "framer-motion";
import {useScroll} from "../Components/useScroll";

const About = () => {
  const [element, controls] = useScroll();
  return (
    <div className='about-container' id="about" ref={element}>
        <div className='container'>
          <motion.div 
            className='details'
            animate={controls}
            variants={reveal}
            transition={{delay: 0.1, stiffness:300}}
          >
            <Title title="O nas" color="blue" />
              <div className="content">

                Wykonujemy projekty na różne okazje np. dedykacje lub życzenia na płytkach aluminiowych lub nierdzewnych o dowolnym rozmiarze na materiale powierzonym lub naszym.

                Możesz u nas zamówić grawer o dowolnym kształcie i na dowolnej blaszce, np. do personalizacji szafek w szatni, bądź drzwi gabinetów po wcześniejszej wycenie projektu.

                Oferujemy wsparcie graficzne przy wykonaniu projektu oraz wysyłamy wizualizację do akceptacji przed przystąpieniem do grawerowania.

                Serdecznie zapraszamy.

              </div>
            <Button content="Nasze realizacje" href='#realizations' />
          </motion.div>
          <div className='cards' ref={element}>
            <Card title="Wsparcie graficzne" logo={<BsPencilSquare />}/>
            <Card title="Wycena indywidualna każdego projektu" logo={<FaCommentsDollar />}/>
            <Card title="Paragon / na życzenie faktura VAT" logo={<FaFileInvoice />} />
            <Card title="Wysyłka / DPD" logo={<GoPackage />}/>
          </div>
        </div>
    </div>
  )
}

export default About
import React from 'react'
import "../Styles/Components/Card.scss";
import {cardAnimation} from "../Animation/Animiation";
import {motion} from 'framer-motion';

const Card = ({
  logo, 
  title,
  animateCustom,
  description = "", 
  isMail = false, 
  isPhone = false 
}) => {
  return (
    <motion.div 
      className='card'
      variants={cardAnimation}
      animate={animateCustom}
      transition={{stiffness:5000}}
      dragDirectionLock
    >
    <div className='logo'> {logo} </div>
    <div className='card-title'>
        <h4>{title}</h4>
        {description}
    </div>
    </motion.div>
  )
}

export default Card
import realizationsData from '../Assets/Realizations/realizationsData.json'

const RealizationDataProvider = (...names) => {
    const realizationNames = names.length > 0 ? names : Object.keys(realizationsData);
    const realizations = [];

    Object.entries(realizationsData).forEach((realizationData) => {
        if (realizationNames.includes(realizationData[0])) {
            realizations.push(realizationData[1]);
        }
    });

    return realizations;
}

export default RealizationDataProvider;
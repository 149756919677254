import React from 'react'
import '../Styles/Components/BrandName.scss';

const BrandName = ({isFooter = false}) => {
    return (
        <div className={`brand ${isFooter ? "footer" : ""}` }>
            <span>ormet</span>
        </div>
    )
}

export default BrandName
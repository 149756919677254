import React, {useState} from 'react'
import {BiUpArrow} from 'react-icons/bi';
import "../Styles/Components/ScrollToTop.scss";

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);
    function toggleVisible() {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 300){
            setVisible(true);
        }else if (scrolled <= 300) {
            setVisible(false);
        }
    }
    function scrollToTop() {
        window.scrollTo({ top: 0 });
    }
    window.addEventListener("scroll", toggleVisible)
    return (
        <>
            {visible && 
                <div className='scrollToTop' onClick={scrollToTop}>
                    <BiUpArrow />
                </div>
            }
        </>);
}

export default ScrollToTop
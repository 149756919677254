import React from 'react';
import '../Styles/Sections/Footer.scss';
import {FaFacebookF} from 'react-icons/fa';
import AllegroLogo from '../Assets/allegroLogo.png';

const Footer = () => {
  return (
    <div className="footer-container">
        <div className="container">
            <div className="copyright">
                <div className="copy">
                    <p>Copyright c 2022. Wszeklie prawa zastrzeżone - Ormet</p>
                </div>
                <div className="icons">
                    <div className="icon">
                        <a href="https://www.facebook.com/profile.php?id=100081573152776">
                            <FaFacebookF />
                        </a>
                    </div>
                    <div className="allegro">
                        <a href="https://allegro.pl/uzytkownik/Ormet_pl">
                            <img src={AllegroLogo} alt="Oferta allegro"/>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
import React from 'react'
import Realization from '../Components/Realization';
import Title from '../Components/Title';
import {textAnimation} from '../Animation/Animiation';
import { useScroll } from '../Components/useScroll';
import "../Styles/Sections/Realizations.scss";
import {motion} from "framer-motion";
import RealizationDataProvider from "../Components/RealizationDataProvider";

const Realizations = () => {
    const [element, controls] = useScroll();
      return (
          <div className='realizations-container' id="realizations" ref={element}>
            <div className="container">
              <motion.div
                  className="title-container"
                  variants={textAnimation}
                  animate={controls}
                  transition={{duration: 1}}
                  dragDirectionLock
              >
                <Title title="Przykładowe realizacje" color="blue" lineCenter={true} />
              </motion.div>
              <div className="realizations">
                {
                    RealizationDataProvider().map((realizationData, key) => {
                    return (
                            <Realization
                                key={key}
                                smallImage={realizationData.smallImageSrc}
                                image={realizationData.imageSrc}
                                description={realizationData.description}
                                example={true}
                            />
                        )
                  })
                }
              </div>
            </div>
          </div>
      )
}

export default Realizations
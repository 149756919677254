import React from 'react'
import Card from '../Components/Card'
import Title from '../Components/Title'
import {BiPhone} from 'react-icons/bi'
import {GoMail} from 'react-icons/go'
import "../Styles/Sections/Contact.scss"
import {useScroll} from "../Components/useScroll";

const Contact = () => {
    const [element, controls] = useScroll();
  return (
    <div className='contact-container' id="contact" ref={element}>
        <div className='container'>
            <div className='details'>
            <Title title="Skontaktuj się z nami" color="blue" />

                Tomasz Kędzierski Ormet

                ul. Orla 43, 64-920 Piła

                NIP: 7811114787

                REGON: 301456508

           </div>
            <div className='cards'>

                <a href={`tel:605 252 919`}>
                    <Card
                        title="Telefon"
                        logo={<BiPhone />}
                        animateCustom={controls}
                        description='605 252 919'

                    />
                </a>
            <a href={`mailto:info@ormet.pl`}>
                <Card
                    title="Mail"
                    logo={<GoMail />}
                    animateCustom={controls}
                    description='info@ormet.pl'
                />
            </a>
            </div>
        </div>
    </div>
  )
}

export default Contact
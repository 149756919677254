import React from "react";
// import b1 from '../Assets/Realizations/Images/B1.jpg';
// import n4 from '../Assets/Realizations/Images/N4.jpg';
// import n3 from '../Assets/Realizations/Images/N3.jpg';
// import b1small from '../Assets/Realizations/Images/B1_small.jpg';
// import n4small from '../Assets/Realizations/Images/N4_small.jpg';
// import n3small from '../Assets/Realizations/Images/N3_small.jpg';
import "../Styles/Sections/Starter.scss";
import "../Styles/Sections/Realizations.scss";
import Navbar from "../Components/Navbar";
import Realization from '../Components/Realization';
import {cardAnimation} from "../Animation/Animiation";
import {useScroll} from "../Components/useScroll";
import RealizationDataProvider from "../Components/RealizationDataProvider";

const Starter = () => {
    const [element, controls] = useScroll();

    return (
        <div className="starter-container" ref={element}>
            <Navbar />
            <div className="container">
                <div className="content">
                    <h1>
                        Znakowanie laserowe
                    </h1>
                    <p>
                        Wykonujemy znakowanie laserowe dowolnej grafiki o wymiarach nie przekraczających <strong>150 x 150 mm</strong> na wszelkich materiałach metalowych.
                    </p>
                    <div className="posibilites">
                        Istnieje także możliwość wykonywania napisów:
                    </div>
                    <ul>
                        <li>
                             w różnych kolorach na stali nierdzewnej
                        </li>
                        <li>
                            w kolorze czarnym na aluminium anodowanym
                        </li>
                    </ul>
                </div>
                <div className="realizations-large">
                    {
                        RealizationDataProvider('B1', 'N3', 'N4').map((realizationData, key) => {
                            return (
                                <Realization
                                    key={key}
                                    smallImage={realizationData.smallImageSrc}
                                    image={realizationData.imageSrc}
                                    withDescription={false}
                                    description={realizationData.description}
                                    variants={cardAnimation}
                                    animate={controls}
                                    large={true}
                                />
                            )
                        })
                    }
                    </div>
            </div>
        </div>
    )
}

export default Starter;
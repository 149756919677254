import ScrollToTop from "./Components/ScrollToTop";
import About from "./Sections/About";
import Contact from "./Sections/Contact";
import Footer from "./Sections/Footer";
import Realizations from "./Sections/Realizations";
import Starter from "./Sections/Starter";
import {motion} from 'framer-motion';

function App() {
  return (
    <motion.div initial="hidden" animate="show">
      <ScrollToTop />
      <Starter />
      <About />
      <Realizations />
      <Contact />
      <Footer />
    </motion.div>
  );
}
export default App;

import React, {useState} from 'react'
import Button from './Button';
import {GiHamburgerMenu} from 'react-icons/gi'
import {MdClose} from 'react-icons/md';
import '../Styles/Components/Navbar.scss';
import BrandName from './BrandName';
import {motion} from 'framer-motion';
import {navbarAnimation} from '../Animation/Animiation';

const Navbar = () => {
    const [toggleNavbar, setToggleNavbar] = useState(false);
    const navbarToggler = () => {
        setToggleNavbar(!toggleNavbar);
    }
    return (
        <motion.div 
            className={`navbar ${toggleNavbar ? "active" : ""}`}
            variants={navbarAnimation}
            transition={{delay: 0.1}}
        >
            <div className='col'>
                <BrandName />
                <div className='collapse-button'>
                    {!toggleNavbar ? (
                        <GiHamburgerMenu onClick={navbarToggler}/>
                    ) : (
                        <MdClose onClick={navbarToggler} /> 
                    )}
                </div>
            </div>
            <nav>
                <div className='links'>
                    <ul>
                        <li>
                            <a href="#about">O nas</a>
                        </li>
                        <li>
                            <a href="#realizations">Przykładowe realizacje</a>
                        </li>
                        <li>
                            <Button content="Kontakt" href="#contact" />
                        </li>
                    </ul>
                </div>
            </nav>
        </motion.div>
    )
}

export default Navbar
import React from 'react'
import "../Styles/Components/Button.scss"

const Button = ({content, icon="", color="blue", href="" }) => {
  return (
    <a href={href}>
      <button className={`${color}`}>
          {content} {icon}
      </button>
    </a>
  );
}

export default Button